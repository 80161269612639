exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-driver-[datetime]-tsx": () => import("./../../../src/pages/admin/driver/[datetime].tsx" /* webpackChunkName: "component---src-pages-admin-driver-[datetime]-tsx" */),
  "component---src-pages-admin-driver-header-tab-tsx": () => import("./../../../src/pages/admin/driver/header-tab.tsx" /* webpackChunkName: "component---src-pages-admin-driver-header-tab-tsx" */),
  "component---src-pages-admin-driver-index-tsx": () => import("./../../../src/pages/admin/driver/index.tsx" /* webpackChunkName: "component---src-pages-admin-driver-index-tsx" */),
  "component---src-pages-admin-gant-tsx": () => import("./../../../src/pages/admin/gant.tsx" /* webpackChunkName: "component---src-pages-admin-gant-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-layout-tsx": () => import("./../../../src/pages/admin/layout.tsx" /* webpackChunkName: "component---src-pages-admin-layout-tsx" */),
  "component---src-pages-admin-orders-index-tsx": () => import("./../../../src/pages/admin/orders/index.tsx" /* webpackChunkName: "component---src-pages-admin-orders-index-tsx" */),
  "component---src-pages-admin-orders-next-days-tsx": () => import("./../../../src/pages/admin/orders/next-days.tsx" /* webpackChunkName: "component---src-pages-admin-orders-next-days-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-order-auth-tsx": () => import("./../../../src/pages/order/auth.tsx" /* webpackChunkName: "component---src-pages-order-auth-tsx" */),
  "component---src-pages-order-detail-[id]-tsx": () => import("./../../../src/pages/order/detail/[id].tsx" /* webpackChunkName: "component---src-pages-order-detail-[id]-tsx" */),
  "component---src-pages-order-index-tsx": () => import("./../../../src/pages/order/index.tsx" /* webpackChunkName: "component---src-pages-order-index-tsx" */),
  "component---src-pages-order-payment-tsx": () => import("./../../../src/pages/order/payment.tsx" /* webpackChunkName: "component---src-pages-order-payment-tsx" */),
  "component---src-pages-product-tepovac-karcher-101-branik-tsx": () => import("./../../../src/pages/product/tepovac-karcher-101/branik.tsx" /* webpackChunkName: "component---src-pages-product-tepovac-karcher-101-branik-tsx" */),
  "component---src-pages-product-tepovac-karcher-101-manual-tsx": () => import("./../../../src/pages/product/tepovac-karcher-101/manual.tsx" /* webpackChunkName: "component---src-pages-product-tepovac-karcher-101-manual-tsx" */),
  "component---src-pages-product-tepovac-karcher-101-old-tsx": () => import("./../../../src/pages/product/tepovac-karcher-101/old.tsx" /* webpackChunkName: "component---src-pages-product-tepovac-karcher-101-old-tsx" */),
  "component---src-pages-uklid-tsx": () => import("./../../../src/pages/uklid.tsx" /* webpackChunkName: "component---src-pages-uklid-tsx" */),
  "component---src-templates-article-detail-tsx": () => import("./../../../src/templates/article-detail.tsx" /* webpackChunkName: "component---src-templates-article-detail-tsx" */),
  "component---src-templates-product-detail-tsx": () => import("./../../../src/templates/product-detail.tsx" /* webpackChunkName: "component---src-templates-product-detail-tsx" */)
}

